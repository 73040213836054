import React from "react";
import { cover } from "polished";

import {
  Box,
  Button,
  FlexCol,
  FlexGrid,
  H3,
  PrismicImage,
  PrismicLink,
  RichText,
  SectionWrapper,
} from "src/components";

const AltCTA = ({ description, title, image, buttonText, buttonLink }) => (
  <>
    {(description || title) && (
      <SectionWrapper py={[8, 9, 10]} bg="brand.secondary" maxWidth={900}>
        <Box width={1} mx="auto">
          <FlexGrid gutterX={[7, 7, 7, 9]} gutterY={[5]}>
            {image && (
              <FlexCol
                justifyContent="center"
                alignItems="center"
                width={[1, 1, 1 / 2]}
              >
                <Box width={1} position="relative" minHeight="50vh">
                  <PrismicImage
                    image={image}
                    style={{ ...cover(), width: "100%", height: "100%" }}
                  />
                </Box>
              </FlexCol>
            )}
            <FlexCol
              justifyContent="center"
              alignItems={["center", "flex-start"]}
              width={[1, 1, 1 / 2]}
            >
              {title && (
                <H3
                  children={title}
                  textAlign={["center", "left"]}
                  color={"text.default"}
                />
              )}
              {description && (
                <Box mt={3} textAlign={["center", "left"]}>
                  <RichText color={"text.default"}>{description}</RichText>
                </Box>
              )}
              {buttonText && buttonLink && (
                <Box mt={5}>
                  <Button
                    children={buttonText}
                    as={PrismicLink}
                    link={buttonLink}
                    variant={"defaultAlt"}
                  />
                </Box>
              )}
            </FlexCol>
          </FlexGrid>
        </Box>
      </SectionWrapper>
    )}
  </>
);

export default AltCTA;
