import React from "react";
import { ThemeProvider } from "styled-components";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { lighten, rgba } from "polished";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import { SEO, Theme, PageHeaderV2, RibbonCTA } from "src/components";

import AltCTA from "./_components/_altCTA";
import Intro from "./_components/_intro";
import Venue from "./_components/_venue";
import Media from "./_components/_media";
import Highlights from "./_components/_highlights";
import More from "./_components/_more";

const FestivalAboutPage = ({ data, setInvert, setInvertLogo }) => {
  let festival = {};
  if (data.festival) {
    if (data.festival.dataString) {
      festival = JSON.parse(data.festival.dataString);
      festival.uid = data.festival.uid;
    }
  }
  let activeFestival = JSON.parse(data.activeFestival.dataString);

  return (
    <>
      <SEO
        title={
          "About " +
          (festival.about_title && RichText.asText(festival.about_title))
        }
        description={
          festival.about_intro_description &&
          RichText.asText(festival.about_intro_description)
        }
        image={
          festival.opengraph_image &&
          festival.opengraph_image.url &&
          festival.opengraph_image.url
        }
        imageAlt={
          festival.opengraph_image &&
          festival.opengraph_image.alt &&
          festival.opengraph_image.alt
        }
      />
      <ThemeProvider
        theme={Theme({
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        {festival.about_title && festival.about_title[0] && (
          <PageHeaderV2
            image={festival.about_image}
            heading={RichText.asText(festival.about_title)}
            links={
              festival.tickets_available === "Yes"
                ? [
                    activeFestival.show_lineup == "Yes" && {
                      label: "Lineup",
                      href: "/" + festival.uid + "/" + "/lineup/",
                    },
                    {
                      label: "Tickets",
                      href: "/" + festival.uid + "/" + "/tickets/",
                    },
                    activeFestival.show_schedule == "Yes" && {
                      label: "Schedule",
                      href: "/" + festival.uid + "/" + "/schedule/",
                    },
                    // {
                    //   label: "FAQ",
                    //   href: "/" + festival.uid + "/" + "/faq/",
                    // },
                  ]
                : [
                    "About",
                    festival.quotes &&
                      festival.quotes[0] &&
                      festival.quotes[0].quote &&
                      "Press",
                    festival.highlights_title &&
                      festival.highlights_title[0] &&
                      "Highlights",
                  ]
            }
            color="brand.primary"
            invert={true}
            setInvertLogo={setInvertLogo}
            setInvert={setInvert}
          />
        )}
      </ThemeProvider>
      <ThemeProvider
        theme={Theme({
          text:
            festival.theme === "Dark theme"
              ? {
                  default: "#fff",
                  body: rgba("#fff", 0.75),
                  alt: rgba("#fff", 0.5),
                  reverse: "#1B1A19",
                  reverseBody: rgba("#1B1A19", 0.75),
                  reverseAlt: rgba("#1B1A19", 0.5),
                }
              : undefined,
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        <>
          <Intro
            title={
              festival.about_intro_title &&
              festival.about_intro_title[0] &&
              RichText.asText(festival.about_intro_title)
            }
            description={
              festival.about_intro_description &&
              festival.about_intro_description[0] &&
              RichText.render(
                festival.about_intro_description,
                linkResolver,
                serializer
              )
            }
            buttonText={festival.about_intro_button_label}
            buttonLink={festival.about_intro_button_link}
          />
          <Venue
            image={festival.about_callout_image}
            title={
              festival.about_callout_title &&
              festival.about_callout_title[0] &&
              RichText.asText(festival.about_callout_title)
            }
            description={
              festival.about_callout_description &&
              festival.about_callout_description[0] &&
              RichText.render(
                festival.about_callout_description,
                linkResolver,
                serializer
              )
            }
          />
          <Media
            video={
              festival.media_video_link &&
              festival.media_video_link.url &&
              festival.media_video_link.url
            }
            quotes={
              festival.quotes &&
              festival.quotes[0] &&
              festival.quotes[0].quote &&
              festival.quotes
            }
          />
          {festival.disclaimer_title && festival.disclaimer_title[0] && (
            <AltCTA
              image={
                festival.disclaimer_image &&
                festival.disclaimer_image.url &&
                festival.disclaimer_image
              }
              title={
                festival.disclaimer_title &&
                festival.disclaimer_title[0] &&
                RichText.asText(festival.disclaimer_title)
              }
              description={
                festival.disclaimer_description &&
                festival.disclaimer_description[0] &&
                RichText.render(
                  festival.disclaimer_description,
                  linkResolver,
                  serializer
                )
              }
              buttonText={festival.disclaimer_button_text}
              buttonLink={festival.disclaimer_button_link}
            />
          )}
          <Highlights
            images={
              festival.highlight_images &&
              festival.highlight_images[0] &&
              festival.highlight_images[0].image &&
              festival.highlight_images[0].image.url &&
              festival.highlight_images
            }
            title={
              festival.highlights_title &&
              festival.highlights_title[0] &&
              RichText.asText(festival.highlights_title)
            }
            description={
              festival.highlights_description &&
              festival.highlights_description[0] &&
              RichText.render(
                festival.highlights_description,
                linkResolver,
                serializer
              )
            }
          />
          <More
            image={
              festival.about_more_image &&
              festival.about_more_image.url &&
              festival.about_more_image
            }
            title={
              festival.more_title &&
              festival.more_title[0] &&
              RichText.asText(festival.more_title)
            }
            description={
              festival.more_description &&
              festival.more_description[0] &&
              RichText.render(
                festival.more_description,
                linkResolver,
                serializer
              )
            }
          />
          {festival.about_cta_heading && festival.about_cta_heading[0] && (
            <RibbonCTA
              heading={
                festival.about_cta_heading &&
                RichText.asText(festival.about_cta_heading)
              }
              body={festival.about_cta_subheading}
              buttonText={festival.about_cta_button_text}
              buttonLink={festival.about_cta_button_link}
              color="brand.tertiary"
              invert={true}
            />
          )}
        </>
      </ThemeProvider>
    </>
  );
};

export default FestivalAboutPage;

export const query = graphql`
  query ($id: String!) {
    festival: prismicFestival(id: { eq: $id }) {
      prismicId
      uid
      dataString
    }
    activeFestival: prismicActiveFestival {
      dataString
      data {
        ongoing_festival {
          document {
            ... on PrismicFestival {
              id
              data {
                tagline
                title {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`;
