import React from "react";
import { Link } from "gatsby";

import {
  Box,
  Button,
  FlexGrid,
  FlexCol,
  H6,
  SectionWrapper,
  Text
} from "src/components";

const Quote = ({ quote, source, link }) => (
  <FlexCol position="relative" width={"80%"} height="100%" color="text.default">
    <Box
      as="svg"
      width="90"
      height="81"
      viewBox="0 0 90 81"
      fill={"currentColor"}
      xmlns="http://www.w3.org/2000/svg"
      position="absolute"
      top="0"
      left="50%"
      css={`
        transform: translate(-50%, -50%);
      `}
    >
      <path
        opacity={0.125}
        d="M37.728 63.432C37.728 57.096 33.12 49.32 23.904 49.32C21.024 49.32 16.416 50.472 14.4 51.912C13.536 49.608 13.248 47.304 13.248 45.288C13.248 30.312 21.312 19.656 29.376 11.016C30.816 9.57599 31.68 7.84799 29.664 5.832L24.192 0.935998C22.752 -0.216004 21.312 -0.503995 19.584 1.224C9.504 12.168 0 27.432 0 49.32C0 66.888 7.488 81 21.6 81C31.968 81 37.728 73.512 37.728 63.432ZM89.568 63.432C89.568 57.096 84.96 49.32 75.744 49.32C72.864 49.32 68.256 50.472 66.24 51.912C65.376 49.608 65.088 47.304 65.088 45.288C65.088 30.312 73.152 19.656 81.216 11.016C82.656 9.57599 83.52 7.84799 81.504 5.832L76.032 0.935998C74.592 -0.216004 73.152 -0.503995 71.424 1.224C61.344 12.168 51.84 27.432 51.84 49.32C51.84 66.888 59.328 81 73.44 81C83.808 81 89.568 73.512 89.568 63.432Z"
      />
    </Box>
    <Text
      children={quote}
      color="text.default"
      as={"a"}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      fontSize={[2, 3]}
      css={`
        text-align: center;
        text-decoration: none;
      `}
    />
    <H6 children={source} mt={[2, 3]} textAlign="center" />
  </FlexCol>
);

const Media = ({ video, quotes }) => (
  <>
    {video && (
      <SectionWrapper py={[4, 5, 6]} bg="bg.default" id="media-coverage">
        <div
          css={`
            position: relative;
            padding-bottom: 53%; /* 16:9 */
            height: 0;
            background-color: #000;
            border-radius: 5px;
            overflow: hidden;
          `}
        >
          <iframe
            width="849"
            height="503"
            src={video + "?showinfo=0&rel=0"}
            frameborder="0"
            allowfullscreen
            title="mediaHighlightVideo"
            css={`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              vertical-align: middle;
              border: none;
              background: transparent;
            `}
          />
        </div>
      </SectionWrapper>
    )}
    {quotes && (
      <SectionWrapper
        pt={[4, 5, 6]}
        pb={[8, 9, 10]}
        bg="bg.default"
        id="media-coverage"
      >
        <FlexGrid gutterX={[7, 7, 7, 9]} gutterY={[8, 9, 10]}>
          {quotes.map((quote, index) => (
            <FlexCol
              justifyContent="center"
              alignItems="center"
              width={[1, 1 / 2]}
              key={"festivalQuote" + index}
            >
              <Quote
                quote={quote.quote}
                source={quote.source}
                link={quote.link.url}
              />
            </FlexCol>
          ))}
        </FlexGrid>
        <FlexCol alignItems="center" mt={[3, 4]}>
          <Button
            children={`See all press`}
            as={Link}
            to="/news?filter=press"
            variant="defaultAlt"
          />
        </FlexCol>
      </SectionWrapper>
    )}
  </>
);

export default Media;
