import React from "react";
import { Link } from "gatsby";
import { cover } from "polished";

import {
  Button,
  FlexCol,
  FlexRow,
  H2,
  RichText,
  SectionWrapper,
} from "src/components";

import Collage from "../../_components/_collageSlice";

const Highlights = ({ images, description, title }) => (
  <>
    {(description || title) && (
      <>
        {images && (
          <FlexRow
            bg="bg.default"
            justifyContent="space-between"
            alignItems="center"
            pt={[8, 9, 10]}
            ml={[-4, -5]}
          >
            <Collage items={images} />
            {/* {images.slice(0, 6).map((image, index) => (
              <Box
                display={index >= 3 ? ["none", "none", "block"] : "block"}
                width={["30%", "30%", 1 / 6]}
                ml={[4, 5]}
                minHeight={[256, 296]}
                bg="bg.wash"
                mt={
                  index === 0
                    ? 6
                    : index % 3 === 1
                    ? 10
                    : index % 3 === 2
                    ? 6
                    : 0
                }
                key={"highlightImage" + index}
                position="relative"
              >
                <PrismicImage
                  image={image.image}
                  style={{ ...cover(), width: "100%", height: "100%" }}
                />
              </Box>
            ))} */}
          </FlexRow>
        )}
        <SectionWrapper
          pt={[4, 5, 6]}
          pb={[8, 9, 10]}
          maxWidth={0}
          bg="bg.default"
          id="highlights"
        >
          {title && (
            <H2 textAlign="center" mb={[4, 5]}>
              {title}
            </H2>
          )}
          {description && (
            <RichText
              textAlign="center"
              fontSize={[1, 2]}
              color="text.body"
              mb={[4, 5]}
            >
              {description}
            </RichText>
          )}
          <FlexCol alignItems="center">
            <Button
              children={`Explore past lineups`}
              as={Link}
              to="/past-lineups"
              variant="defaultAlt"
            />
          </FlexCol>
        </SectionWrapper>
      </>
    )}
  </>
);

export default Highlights;
