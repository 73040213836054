import React from "react";
import { cover } from "polished";

import {
  Box,
  H2,
  FlexGrid,
  FlexCol,
  RichText,
  PrismicImage,
  SectionWrapper,
} from "src/components";

const Venue = ({ image, description, title }) => (
  <>
    {(description || title) && (
      <SectionWrapper py={[8, 9, 10]} bg="bg.default" maxWidth={900}>
        <Box bg="bg.reverse" position="relative">
          <FlexGrid>
            <FlexCol
              justifyContent="center"
              alignItems="center"
              width={[1, 1, 1 / 2]}
            >
              <Box py={[8, 9]} px={[4, 7, 8]}>
                {title && (
                  <H2 color="text.reverse" mb={[4, 5]}>
                    {title}
                  </H2>
                )}
                {description && (
                  <RichText fontSize={[1, 2]} color="text.reverse">
                    {description}
                  </RichText>
                )}
              </Box>
            </FlexCol>
            {image && (
              <FlexCol justifyContent="center" width={[1, 1, 1 / 2]}>
                <Box pb={[8, 9, 0]} px={[4, 8, 0]}>
                  <Box
                    position={["relative", "relative", "absolute"]}
                    // height={[256, 420, "100%"]}
                    width={[1, 1, "initial"]}
                    left={["initial", "initial", "50%"]}
                    top={["initial", "initial", "-96px"]}
                    right={["initial", "initial", "-96px"]}
                    zIndex={1}
                  >
                    <div
                      css={`
                        position: relative;
                        padding-bottom: ${(image.dimensions.height /
                          image.dimensions.width) *
                          100}%; /* 16:9 */
                        height: 0;
                        background-color: #000;
                        overflow: hidden;
                      `}
                    >
                      <PrismicImage
                        image={image}
                        imgStyle={{ objectFit: "cover" }}
                      />
                    </div>
                    {/* <PrismicImage
                      image={image}
                      style={{ ...cover(), width: "100%", height: "100%" }}
                    /> */}
                  </Box>
                </Box>
              </FlexCol>
            )}
          </FlexGrid>
        </Box>
      </SectionWrapper>
    )}
  </>
);

export default Venue;
