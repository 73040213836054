import React from "react";

import {
  Button,
  FlexRow,
  H2,
  PrismicLink,
  RichText,
  SectionWrapper,
} from "src/components";

const Intro = ({ buttonText, buttonLink, description, title }) => (
  <>
    {(description || title) && (
      <SectionWrapper py={[6, 7, 8]} maxWidth={0} bg="bg.default" id="about">
        {title && (
          <H2 textAlign="center" mb={[4, 5]}>
            {title}
          </H2>
        )}
        {description && (
          <RichText textAlign="center" fontSize={[1, 2]} color="text.body">
            {description}
          </RichText>
        )}
        {buttonText && buttonLink && (
          <FlexRow justifyContent="center" mt={5}>
            <Button
              children={buttonText}
              as={PrismicLink}
              link={buttonLink}
              variant={"defaultAlt"}
            />
          </FlexRow>
        )}
      </SectionWrapper>
    )}
  </>
);

export default Intro;
